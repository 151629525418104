import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const FourOhFour = () => {
  return (
    <Layout section="main" page="404">
      <section className="bg-fuchsia-010 py-10 md:py-24 page-404">
        <div className="container mx-auto">
          <h2 className="leading-12 mb-2 text-center text-md4 font-black text-purple-100 md:text-xxl">
            Page not found
          </h2>
          <p className="text-center text-md3 font-semibold text-purple-100 md:text-md4 mx-auto">
            The page you are looking for doesn’t exist, or some other
            error occurred. Please check the URL or go to the{' '}
            <Link
              to="/"
              className="font-bold focus-visible:m-[-0.125rem] focus-visible:p-[0.125rem] focus-visible:outline-2 focus-visible:outline-black-100"
            >
              homepage
            </Link>
            .
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default FourOhFour;
